import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { PageNotFoundIcon } from 'images/shared';

const NotFound = () => {
  return (
    <Container>
      <InnerContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <h2>Seems like we lost this page</h2>
        <SVGContainer>
          <PageNotFoundIcon primaryColor="#CE9200" secondaryColor="#C1BEAC" />
        </SVGContainer>
        <div />
      </InnerContainer>
      <Overlay
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 2 }}>
        <h2>Let&apos;s get you back home</h2>
        <button type="button">
          <Link to="/">Back To Home</Link>
        </button>
      </Overlay>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  h2 {
    font-weight: 300;
    font-size: 1.75rem;
  }

  button {
    width: 166px;
    height: 46px;
    font-size: 16px;
    line-height: 19px;
    background: var(--ax-gold);
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    transition: 0.375s;
    cursor: pointer;
    &:hover,
    &:focus {
      border: none;
      outline: none;
      background: var(--ax-black);
    }
    a {
      color: white;
      height: 100%;
      width: 100%;
      text-decoration: none;
    }
  }

  @media screen and (min-width: 48rem) {
    justify-content: center;
    h2 {
      font-size: 2rem;
    }
  }

`;

const InnerContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 9.75rem 0 3.125rem;
  margin: 0 auto;
  width: 90%;
  @media screen and (min-width: 48rem) {
    gap: 3rem;
    padding: 12.4rem 0 12.95rem;
    width: 80%;
  }

  @media screen and (min-width: 75rem) {
    gap: 3.25rem;
    padding: 4rem 0 2rem;
    width: 60%;
  }
`;

const SVGContainer = styled.div`
  width: 100%;
  height: auto;
`;

const Overlay = styled(motion.div)`
  align-items: center;
  background: rgba(239, 237, 228, 0.6);
  backdrop-filter: blur(20px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  height: 55%;
  justify-content: center;
  width: 100%;
  left: 0;
  position: absolute;
  @media screen and (min-width: 48rem) {
    height: 47.5%;
  }
  @media screen and (min-width: 64rem) {
    height: 45%;
  }
`;

export default NotFound;
